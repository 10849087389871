<ng-container *ngIf="usState$ | async as state">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div style="width: 100%; max-width: 500px">
      <ng-container *ngIf="state.isSstExcluded && state.isSSUTA; else showForm">
        <div style="text-align: center" class="mat-display-2">Tax Collecting</div>
        <div
          style="text-align: center; padding: 2rem"
          fxLayout="column"
          fxLayoutAlign="start center"
        >
          <div>
            {{ state.name }} is a TaxCloud Member State. To turn on collecting, please contact us at
            <div>
              <a href="mailto:service@taxcloud.net">service&#64;taxcloud.net</a>
              <tc-copy-button
                valueToCopy="service@taxcloud.net"
                description="Support Email"
              ></tc-copy-button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #showForm>
        <form fxLayout="column" fxLayoutAlign="start center" [formGroup]="stateFormGroup">
          <div class="mat-display-2">Tax Collecting</div>

          <tc-alert type="primary" *ngIf="state.collecting === false">
            We need your {{ state.name }} license to turn on sales tax collecting
          </tc-alert>

          <tc-alert type="warning" *ngIf="state.hasPhysicalNexus">
            Collection in states with physical nexus cannot be turned off
          </tc-alert>

          <mat-form-field style="width: 100%; max-width: 500px; padding-bottom: 1rem">
            <input
              matInput
              required
              [placeholder]="state.name + ' License Id'"
              formControlName="stateLicenseId"
            />
            <mat-hint *ngIf="idConstraintMsg">{{ idConstraintMsg }}</mat-hint>
            <mat-error>
              {{ state.name }}
              {{ stateLicenseId.errors?.pattern ? idConstraintMsg : 'License Id Required' }}
            </mat-error>
          </mat-form-field>

          <mat-form-field style="width: 100%">
            <mat-label>Select Filing Frequency</mat-label>
            <mat-select formControlName="frequency">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let option of frequencyOptions" [value]="option.value">
                {{ option.label }}
              </mat-option>
            </mat-select>
            <mat-error>Filing Frequency </mat-error>
          </mat-form-field>

          <mat-form-field style="width: 100%">
            <mat-label>Select Tax Type</mat-label>
            <mat-select formControlName="taxType">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let option of taxTypeOptions" [value]="option.value">
                {{ option.label }}
              </mat-option>
            </mat-select>
            <mat-error>Tax Type Required</mat-error>
          </mat-form-field>

          <mat-form-field style="width: 100%">
            <mat-label>Select Form</mat-label>
            <mat-select formControlName="formSelected">
              <mat-option>Unknown</mat-option>
              <mat-option *ngFor="let option of state.stateLicense.formOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 100%">
            <mat-label>Select Registration Address</mat-label>
            <mat-select formControlName="regAddressId">
              <mat-option *ngFor="let location of locations$ | async" [value]="location.locationId">
                <span
                  >{{ location.address.address1 }} {{ location.address.address2 }}
                  {{ location.address.city }}, {{ location.address.stateAbbreviation }}
                  {{ location.address.zip5 }}
                </span>
              </mat-option>
              <mat-option [routerLink]="'/go/locations'" [queryParams]="{ add: 'true' }">
                <a>Add New</a></mat-option
              >
            </mat-select>
            <mat-error>Location Required </mat-error>
          </mat-form-field>
        </form>

        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1rem">
          <button mat-button (click)="cancel()">CANCEL</button>

          <ng-container *ngIf="state.collecting === false; else basicButton">
            <button
              mat-button
              tcColor="primary"
              [disabled]="stateFormGroup.invalid"
              tcColorDark
              (click)="turnOnCollect()"
            >
              SAVE AND TURN ON
            </button>
          </ng-container>

          <ng-template #basicButton>
            <button
              mat-button
              tcColor="primary"
              [disabled]="stateFormGroup.invalid || stateFormGroup.dirty === false"
              tcColorDark
              (click)="turnOnCollect()"
            >
              SAVE
            </button>
          </ng-template>

          <button
            *ngIf="state.collecting"
            [disabled]="state.hasPhysicalNexus || state.autoFile"
            mat-button
            tcColor="danger"
            tcColorDark
            (click)="turnOffCollect()"
          >
            TURN OFF
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
