<tc-working-spinner [message]="workingMessage$ | async" [opened]="working$ | async">
</tc-working-spinner>
<section>
  <div class="margin_sm" fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutAlign="end center">
      <tc-refresh-us-states-button></tc-refresh-us-states-button>
    </div>

    <ng-container *ngIf="state$ | async as state">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="2rem">
        <div class="mat-title">{{state.name}}</div>

        <tc-alert type="warning" *ngIf="(isAdmin$ | async) === false"
          >Only administrators can change state settings</tc-alert
        >

        <tc-alert *ngIf="shouldCollect$ | async" type="danger">
          According to our records, you are over {{state.name}}'s threshold and should be collecting
          sales tax
        </tc-alert>

        <ul tcList>
          <li tcListItem>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
              <div>Collecting: {{state.collecting ? 'Yes' : 'No'}}</div>
              <div *ngIf="state.collecting">
                Effective Date: {{collectingEffectiveDate$ | async}}
              </div>
              <div *ngIf="!state.collecting">Effective Date: --/--/--</div>
              <div>Tax Type: {{state.collecting ? 'Sales Tax' : 'Use Tax'}}</div>
            </div>
          </li>

          <li tcListItem [lastItem]="'true'">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
              <div>Filing Responsibility: {{filingResponsibility$ | async}}</div>
              <div
                *ngIf="(filingResponsibility$ | async)=='TaxCloud SST' || (filingResponsibility$ | async)=='TaxCloud Direct'"
              >
                Filing Effective Date: {{filingEffectiveDate$ | async}}
              </div>
              <div
                *ngIf="!((filingResponsibility$ | async)=='TaxCloud SST' || (filingResponsibility$ | async)=='TaxCloud Direct')"
              >
                Filing Effective Date: --/--/--
              </div>
            </div>
          </li>
        </ul>

        <ng-container
          *ngIf="(stateIsSsuta$ | async) && (isAutmatedCompliance$ | async) && (isSstExcluded$ | async) === false; else showForm"
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-button (click)="backToList()">BACK</button>
          </div>
        </ng-container>

        <ng-template #showForm>
          <ng-container
            *ngIf="(hasAcceptedLatestTOS$ | async) || (isMasquerading$ | async); else showTosButton"
          >
            <div *ngIf="isMasquerading$ | async; else regularForm">
              <tc-filing-us-state-form
                [masquerading]="true"
                [usState]="state"
              ></tc-filing-us-state-form>
              <tc-direct-filing-form
                [masquerading]="true"
                [usState]="state"
              ></tc-direct-filing-form>
            </div>

            <ng-template #regularForm>
              <div *ngIf="isAdmin$ | async">
                <tc-alert type="primary" *ngIf="state.autoFile">
                  <div
                    style="text-align: center; padding: 2rem"
                    fxLayout="column"
                    fxLayoutAlign="start center"
                    fxLayoutGap="1rem"
                    class="mat-body-bold"
                  >
                    <div>
                      Tax Filing is enabled for {{state.name}}. To turn off tax filing, please
                      <a [routerLink]="'/contact'">contact us.</a>
                    </div>
                  </div>
                </tc-alert>

                <tc-filing-us-state-form [usState]="state"></tc-filing-us-state-form>
                <ng-container *ngIf="state.autoFile; else directFileAd">
                  <tc-direct-filing-form [usState]="state"></tc-direct-filing-form>
                </ng-container>

                <ng-template #directFileAd>
                  <div
                    style="text-align: center; padding: 2rem"
                    fxLayout="column"
                    fxLayoutAlign="start center"
                    fxLayoutGap="1rem"
                  >
                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1rem">
                      <div class="mat-display-3">
                        <label class="new-tag-1">Add</label> Tax Filing
                      </div>
                    </div>
                    Contact us for more information at
                    <div>
                      <a href="mailto:service@taxcloud.net">service&#64;taxcloud.net</a>
                      <tc-copy-button
                        valueToCopy="service@taxcloud.net"
                        description="Support Email"
                      ></tc-copy-button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </ng-template>
          </ng-container>

          <ng-template #showTosButton> </ng-template>
        </ng-template>
      </div>
    </ng-container>
    <tc-faq style="padding: 2rem 1rem" [supportCategory]="supportCategory"> </tc-faq>
  </div>
</section>
