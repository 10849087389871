import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { LocationActions, LocationActionTypes } from './../actions/location.actions'
import { adapter, initialState, State } from './../location.store'

export function reducer(state = initialState, action: LocationActions): State {
  switch (action.type) {
    case LocationActionTypes.InitLocations:
    case LocationActionTypes.InitLocationsPaAttestation:
    case LocationActionTypes.InitLocationsGetStartedtGoLive:
    case LocationActionTypes.InitLocationsProspective:
      return {
        ...state,
        working: 'Updating locations',
      }

    case LocationActionTypes.RefreshLocations:
      return {
        ...adapter.removeAll(state),
        working: 'Refreshing locations',
      }

    case LocationActionTypes.RefreshLocationsSuccess: {
      return {
        ...adapter.upsertMany(action.payload.locations, state),
        source: DataSourceEnum.API,
        initialLoadComlete: true,
        working: '',
      }
    }

    case LocationActionTypes.InitLocationsSuccess: {
      return {
        ...adapter.upsertMany(action.payload.locations, state),
        source: action.payload.source,
        initialLoadComlete: true,
        working: '',
      }
    }

    case LocationActionTypes.InitLocationNotNeeded:
      return {
        ...state,
        working: '',
      }

    case LocationActionTypes.AddLocation:
      return {
        ...state,
        working: 'Adding location',
      }

    case LocationActionTypes.UpdateLocationProspective: {
      return {
        ...adapter.updateOne(action.payload.location, state),
        working: 'Updating location',
      }
    }

    case LocationActionTypes.UpdateLocation: {
      return {
        ...adapter.updateOne(action.payload.update, state),
        working: 'Updating location',
      }
    }

    case LocationActionTypes.DeleteLocation: {
      return {
        ...adapter.removeOne(action.payload.id, state),
        working: 'Removing location',
      }
    }

    case LocationActionTypes.ClearLocations: {
      return {
        ...adapter.removeAll(state),
        initialLoadComlete: false,
        working: '',
        source: DataSourceEnum.Local,
      }
    }

    default: {
      return state
    }
  }
}
