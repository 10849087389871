import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AngularResizeEventModule } from 'angular-resize-event'
import { MarkdownModule } from 'ngx-markdown'
import { MaterialModule } from 'src/app/material/material.module'
import { CopyButtonComponent } from './components/copy-button/copy-button.component'
import { CurrencyColorDirective } from '../init-ui/directives/currency-color/currency-color.directive'
import { InitUiModule } from '../init-ui/init-ui.module'
import { AutomatedComplianceFeaturesComponent } from './components/automated-compliance-features/automated-compliance-features.component'
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component'
import { DatepickerComponent } from './components/datepicker/datepicker.component'
import { EmbeddedSupportComponent } from './components/embedded-support/embedded-support.component'
import { FabTransformComponent } from './components/fab-transform/fab-transform.component'
import { GrandfatheredSelectiveComplianceComponent } from './components/grandfathered-selective-compliance/grandfathered-selective-compliance.component'
import { HorizontalSlideComponent } from './components/horizontal-slide/horizontal-slide.component'
import { HorizontalSwipeComponent } from './components/horizontal-swipe/horizontal-swipe.component'
import { MonthPickerComponent } from './components/month-picker/month-picker.component'
import { CoreServicesFeaturesComponent } from './components/core-services-features/core-services-features.component'
import { CoreServicesComponent } from './components/core-services/core-services.component'
import { TableComponent } from './components/table/table.component'
import { ConfirmDialog } from './dialogs/confirm/confirm.dialog'
import { TcCurrencyPipe } from './pipes/tc-currency/tc-currency.pipe'
import { AdditionalServicesComponent } from './components/additional-services/additional-services.component'
import { ControlRequiredPipe } from './pipes/control-required/control-required.pipe'
import { ResultMessageComponent } from './components/result-message/result-message.component'
import { SharedPrimeNgModule } from 'src/app/primeng/shared-primeng.module'
import { CapitalizeFirstPipe } from './pipes/capitalize-first/capitalizeFirst.pipe'

@NgModule({
  declarations: [
    MonthPickerComponent,
    ConfirmDialog,
    CopyToClipboardComponent,
    HorizontalSlideComponent,
    FabTransformComponent,
    TableComponent,
    TcCurrencyPipe,
    CurrencyColorDirective,
    HorizontalSwipeComponent,
    EmbeddedSupportComponent,
    AutomatedComplianceFeaturesComponent,
    AdditionalServicesComponent,
    CoreServicesComponent,
    CoreServicesFeaturesComponent,
    GrandfatheredSelectiveComplianceComponent,
    DatepickerComponent,
    ControlRequiredPipe,
    ResultMessageComponent,
    CapitalizeFirstPipe,
    CopyButtonComponent,
  ],
  imports: [
    InitUiModule,
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    ScrollingModule,
    RouterModule,
    AngularResizeEventModule,
    MarkdownModule.forRoot(),
    SharedPrimeNgModule,
  ],
  exports: [
    InitUiModule,
    MarkdownModule,
    MonthPickerComponent,
    ConfirmDialog,
    CopyToClipboardComponent,
    HorizontalSlideComponent,
    FabTransformComponent,
    TableComponent,
    TcCurrencyPipe,
    CurrencyColorDirective,
    HorizontalSwipeComponent,
    EmbeddedSupportComponent,
    AutomatedComplianceFeaturesComponent,
    AdditionalServicesComponent,
    CoreServicesComponent,
    CoreServicesFeaturesComponent,
    AngularResizeEventModule,
    GrandfatheredSelectiveComplianceComponent,
    DatepickerComponent,
    ControlRequiredPipe,
    ResultMessageComponent,
    CapitalizeFirstPipe,
    CopyButtonComponent,
  ],
})
export class UiModule {}
