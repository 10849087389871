import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import {
  Observable,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  throwError,
  timeout,
} from 'rxjs'
import { PlanSubscription } from 'src/app/manage-account/models/billing.model'
import * as fromBilling from 'src/app/manage-account/manage-account.state'
import * as billingSelector from 'src/app/manage-account/selectors/billing.selector'
import { State as UserState } from 'src/app/user/user.state'
import * as userSelector from 'src/app/user/selectors/user.selectors'
import { hasCurrentActiveSubscriptionStatus } from 'src/app/utilities/subscription.utilities'
import { omitQueryParamsFromPathname } from 'src/app/utilities/url-helper.utilities'

class PlanGuardTimeoutError extends Error {
  constructor() {
    super(
      'A timeout occurred fetching details about your plan. If this issue persists, please contact support.'
    )
    this.name = 'CustomTimeoutError'
  }
}

@Injectable({
  providedIn: 'root',
})
export class PlanGuard implements CanActivate {
  constructor(
    private billingStore: Store<fromBilling.State>,
    private router: Router,
    private userStore: Store<UserState>
  ) {}
  private PLAN_GUARD_TIMEOUT = 3500

  public subscription$: Observable<PlanSubscription> = this.billingStore.select(
    billingSelector.selectSubscription
  )

  public subscriptionLoading$: Observable<boolean> = this.billingStore.select(
    billingSelector.selectSubscriptionLoading
  )

  public isMasquerading$: Observable<boolean> = this.userStore.select(
    userSelector.selectUserIsMasquerading
  )

  public isSubscriptionLoaded$: Observable<boolean> = this.billingStore.select(
    billingSelector.selectIsSubscriptionsLoaded
  )

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this.subscription$,
      this.subscriptionLoading$,
      this.isMasquerading$,
      this.isSubscriptionLoaded$,
    ]).pipe(
      switchMap(([subscription, subscriptionLoading, isMasquerading, isSubscriptionLoaded]) => {
        const hasPlan =
          !!subscription && hasCurrentActiveSubscriptionStatus(subscription) && !subscriptionLoading

        const pathname = omitQueryParamsFromPathname(state.url)

        const canActivate =
          hasPlan ||
          pathname.includes('edit-plan') ||
          pathname.includes('locations') ||
          isMasquerading

        return of([canActivate, isSubscriptionLoaded])
      }),
      filter(([_, isSubscriptionLoaded]) => isSubscriptionLoaded),
      timeout({
        each: this.PLAN_GUARD_TIMEOUT,
        with: () => throwError(() => new PlanGuardTimeoutError()),
      }),
      map(([canActivate, isSubscriptionLoaded]) => {
        if (canActivate) {
          return true
        } else {
          this.router.navigate(['/go/account/edit-plan'])
          return false
        }
      }),
      catchError((err) => {
        if (err instanceof PlanGuardTimeoutError) {
          console.error('Timeout of plan guard observable')
          return of(true)
        }
        throw err
      })
    )
  }
}
