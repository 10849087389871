import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { adapter, initialState, State } from '../us-states.store'
import { UsStateActions, UsStateActionTypes } from './../actions/us-states.actions'

export function reducer(state = initialState, action: UsStateActions): State {
  switch (action.type) {
    case UsStateActionTypes.InitStatesCollecting:
    case UsStateActionTypes.InitStatesGetStartedShell:
    case UsStateActionTypes.InitStatesGoShell: {
      return {
        ...state,
        working: 'Getting US States',
        isCollectionStatesWorking: true,
      }
    }

    case UsStateActionTypes.RefreshUsStates: {
      return {
        ...adapter.removeAll(state),
        working: 'Refeshing States',
        source: DataSourceEnum.Local,
      }
    }

    case UsStateActionTypes.InitStatesNotNeeded: {
      return {
        ...state,
        working: '',
        isCollectionStatesWorking: false,
      }
    }

    case UsStateActionTypes.InitStatesSuccess: {
      return {
        ...adapter.upsertMany(action.payload.usStates, state),
        source: action.payload.source,
        working: '',
        isCollectionStatesWorking: false,
      }
    }

    case UsStateActionTypes.RefreshUsStatesSuccess: {
      return {
        ...adapter.upsertMany(action.payload.usStates, state),
        source: DataSourceEnum.API,
        working: '',
      }
    }

    case UsStateActionTypes.UpdateBasicFilingLocal:
    case UsStateActionTypes.UpdateDirectFilingLocal: {
      return {
        ...adapter.updateOne(action.payload.update, state),
        working: '',
      }
    }

    case UsStateActionTypes.ClearUsStates: {
      return {
        ...adapter.removeAll(state),
        source: DataSourceEnum.Local,
        working: '',
      }
    }

    default: {
      return state
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
