import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SocialUser } from '@abacritt/angularx-social-login'
import { Observable, throwError } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { LoginTypeEnum } from 'src/app/enums/login.enum'

import { UserDb } from '../db/user.db'
import { IAuth } from '../models/iauth'
import { IRegister } from '../models/iregister.model'
import { IUser } from '../models/iuser'
import { LoginAdapter } from '../utilities/login-adapter.utilities'

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  adapter = new LoginAdapter()
  db = new UserDb()

  constructor(private http: HttpClient) {}

  public register(form: IRegister): Observable<string> {
    let url = 'api/account/register3'
    if (form.storeInfoGuid) {
      url += `?storeinfoguid=${form.storeInfoGuid}`
    }
    const headers = new HttpHeaders({
      ContentType: 'application/x-www-form-urlencoded',
    })

    const formData = new FormData()
    formData.set('company', form.company)
    formData.set('email', form.email)
    formData.set('firstName', form.firstName)
    formData.set('lastName', form.lastName)
    formData.set('phone', form.phone)
    formData.set('password', form.password)
    formData.set('passwordConfirm', form.confirm)

    return this.http
      .post(url, formData, {
        headers,
        responseType: 'text',
      })
      .pipe(
        map((response) => {
          if (response !== 'success') {
            throw new Error(response)
          }
          return form.email
        }),
        catchError((error) => {
          console.error('Bad Request:', error.error)
          if (error.error === 'The email address is already in use') {
            return throwError(() => new Error('Email already in use'))
          }
          return throwError(() => error)
        })
      )
  }

  public registerOauth(user: SocialUser): Observable<[IUser, IAuth]> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/x-www-form-urlencoded',
      }),
    }

    const formData = new FormData()
    formData.set('vendor', 'google')
    formData.set('code', user.authorizationCode)

    return this.http.post(`api/account/registeroauth`, formData, httpOptions).pipe(
      tap((_) => this.db.saveLoginType(LoginTypeEnum.Default)),
      map((data) => this.adapter.getUserAndAuthFromReturn(data))
    )
  }

  public confirmEmail(token: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/x-www-form-urlencoded',
      }),
    }
    const formData = new FormData()
    formData.set('ID', token)

    return this.http.post(`api/account/confirmemail`, formData, httpOptions).pipe(
      map((result: boolean) => {
        if (result) {
          return true
        }
        throw new Error('Error result')
      })
    )
  }
}
