import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { Router } from '@angular/router'
import { AppStatusService } from '../../services/app-status.service'

@Component({
  templateUrl: './conversion-status.dialog.html',
  styleUrls: ['./conversion-status.dialog.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversionStatusDialog implements OnInit {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<AppStatusService>,
    private router: Router
  ) {}

  ngOnInit(): void {}
  goToStores(): void {
    this.bottomSheetRef.dismiss()
    this.router.navigate(['/go/integrations'])
  }
}
