<ul class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs$ | async">
    <a
      [routerLink]="getRouterLink(breadcrumb)"
      aria-current="page"
      attr.aria-label="{{ getRouterLink(breadcrumb) }}"
    >
      {{ breadcrumb.label }}
    </a>
  </li>
</ul>
