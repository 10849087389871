<ng-container *ngIf="justCopied$ | async; else readyToCopy">
  <i
    class="pi ml-3 cursor-pointer pi-check"
    style="font-size: larger"
    pTooltip="Copy"
    tooltipPosition="top"
  ></i>
</ng-container>
<ng-template #readyToCopy>
  <i
    (click)="copyMessage()"
    [class]="getReadyToCopyClass()"
    style="font-size: larger"
    pTooltip="Copy"
    tooltipPosition="top"
  ></i>
</ng-template>
