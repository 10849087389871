import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import { LoginActionTypes } from 'src/app/user/actions/login.actions'
import { Router } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, Observable } from 'rxjs'
import { delay, filter, first, map, pluck, tap } from 'rxjs/operators'
import { AppTypeEnum } from 'src/app/enums/app.enums'
import { LoginEnum } from 'src/app/enums/login.enum'
import { IRouterState } from 'src/app/root/models/irouterstate'
import * as fromAppStatusActions from 'src/app/app-status/actions/app-status.actions'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import {
  LogoutFromRegistraton,
  RegisterRequest,
  RegisterWithFederated,
} from 'src/app/user/actions/login.actions'
import * as authSelector from 'src/app/user/selectors/user.selectors'
import { State as AuthState } from 'src/app/user/user.state'
import { IRegister } from '../../models/iregister.model'
import { Meta, Title } from '@angular/platform-browser'
import { SignUpPageMeta } from '../../constants/user.constants'
import { CanonicalUrlService } from 'src/app/root/services/canonical-url.service'
import { SubSink } from 'subsink'
import { Actions, ofType } from '@ngrx/effects'
import { NotificationService } from 'src/app/notification/services/notification.service'

@Component({
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPage implements OnInit, OnDestroy {
  subs = new SubSink()

  currentRegistration$ = new BehaviorSubject<IRegister>(null)
  storeInfoGuid$ = new BehaviorSubject<string>('')
  isHandlingFormSubmission = false

  constructor(
    private actions$: Actions,
    private router: Router,
    private authStore: Store<AuthState>,
    private appStatusStore: Store<AppStatusState>,
    private route: Store<fromRouter.RouterReducerState<IRouterState>>,
    private meta: Meta,
    private titleService: Title,
    private canonicalUrlService: CanonicalUrlService,
    private notificationService: NotificationService
  ) {
    this.titleService.setTitle('Sign Up for TaxCloud - Create Your TaxCloud Account')
    this.meta.addTags(SignUpPageMeta)
    this.canonicalUrlService.setCanonicalURL('https://app.taxcloud.com/register')
    // Hack way to set background image for this page without changing angular's view encapsulation.
    document.body.style.backgroundImage = "url('/assets/images/taxcloud_reg_bg.png')"
    document.body.style.backgroundSize = 'cover'
  }

  authIsLoading$: Observable<boolean> = this.authStore.select(authSelector.selectAuthorizeIsLoading)

  shouldLogOut$: Observable<boolean> = this.authStore
    .select(authSelector.selectLoginStatus)
    .pipe(map((status) => status !== LoginEnum.LoggedOut))

  emailToVerify$: Observable<string> = this.authStore.select(authSelector.selectEmailToVerify)

  hasError$: Observable<boolean> = this.authStore
    .select(authSelector.selectAuthorizeError)
    .pipe(map((message) => !!message))

  errorMessage$: Observable<string> = this.authStore.select(authSelector.selectAuthorizeError)

  appType$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'AppType'),
    filter((type: string) => !!type)
  )

  email$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'email'),
    filter((email: string) => !!email)
  )

  storeGuid$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'storeinfoguid'),
    filter((storeGuid: string) => !!storeGuid)
  )

  isValid$: Observable<boolean> = this.currentRegistration$.pipe(
    map((registration) => (registration && registration.isValid ? true : false))
  )

  public ngOnInit() {
    this.appType$
      .pipe(
        first(),
        delay(100),
        tap((appType: AppTypeEnum) => {
          this.appStatusStore.dispatch(
            new fromAppStatusActions.UpdateAppType({ appType: AppTypeEnum.Embedded })
          )
        })
      )
      .subscribe()

    this.storeGuid$
      .pipe(
        first(),
        delay(100),
        tap((storeGuid) => this.storeInfoGuid$.next(storeGuid))
      )
      .subscribe()

    this.subs.add(
      this.actions$
        .pipe(ofType(LoginActionTypes.RegisterRequestFailure))
        .subscribe((action: { payload: { error: string } }) => {
          this.isHandlingFormSubmission = false
          this.notificationService.showError({
            title: action.payload.error,
          })
        })
    )
  }

  public register(registration: IRegister): void {
    this.isHandlingFormSubmission = true
    const form = registration
    if (this.storeInfoGuid$.value) {
      form.storeInfoGuid = this.storeInfoGuid$.value
    }

    form.confirm = form.password // Temp until we remove confirm password requirement from API
    this.authStore.dispatch(new RegisterRequest({ form, autoLogin: true }))
  }

  public registerWithGoogle(): void {
    this.authStore.dispatch(new RegisterWithFederated())
  }

  public login(): void {
    document.body.style.backgroundImage = null
    document.body.style.backgroundSize = null
    if (this.storeInfoGuid$.value) {
      this.router.navigate(['/sign-in'], {
        queryParams: { storeinfoguid: this.storeInfoGuid$.value },
      })
    } else {
      this.router.navigate(['/sign-in'])
    }
  }

  public logout(): void {
    this.authStore.dispatch(new LogoutFromRegistraton())
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
