import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import { ServicePlanTypeEnum } from 'src/app/enums/service-plan.enums'
import { SupportCategoryEnum } from 'src/app/enums/support.enums'
import { UsStateEnum } from 'src/app/enums/us-state.enums'
import * as stateSelector from 'src/app/us-states/selectors/us-state.selector'

import { State as UsStateState } from '../../us-states.store'
import { State as AccountStoreState } from 'src/app/account/account.state'
import { IUser } from 'src/app/user/models/iuser'
import { State as UserStateStore } from 'src/app/user/user.state'
import * as authorizeStoreSelectors from 'src/app/user/selectors/user.selectors'

@Component({
  templateUrl: './list-collecting-us-states.page.html',
  styleUrls: ['./list-collecting-us-states.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListCollectingUsStatesPage implements OnInit {
  public supportCategory = SupportCategoryEnum.ManageUsStates

  constructor(
    private usStateStore: Store<UsStateState>,
    private accountStore: Store<AccountStoreState>,
    private userStore: Store<UserStateStore>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public working$: Observable<boolean> = this.usStateStore.select(stateSelector.selectIsWorking)

  public workingMessage$: Observable<string> = this.usStateStore.select(
    stateSelector.selectWorkingMessage
  )
  public user$: Observable<IUser> = this.userStore.select(
    authorizeStoreSelectors.selectAuthorizeUser
  )

  isAutomatedServicePlan$: Observable<boolean> = this.accountStore
    .select(accountSelector.selectServicePlan)
    .pipe(map((plan) => plan.type === ServicePlanTypeEnum.Automated))

  ngOnInit(): void {}

  public selectState(state: UsStateEnum): void {
    this.router.navigate([state], {
      relativeTo: this.route,
    })
  }
}
