<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1rm" class="margin_b_sm">
  <div class="mat-display-1">Welcome to TaxCloud!</div>
  <div>
    Prior to using TaxCloud to automatically remit your sales taxes, You need to complete "Go Live"
    on your connection.
  </div>
  <div>Please complete this step so your connection is live.</div>
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-button tcColor="success" tcColorDark (click)="goToStores()">GO</button>
  </div>
</div>
