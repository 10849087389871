export const getURLPathname = (url: string) => {
  try {
    return new URL(url).pathname
  } catch {
    // UI navigation uses pathnames ('/go/home')
    const isPathnameOnly = url.startsWith('/') || (/^[a-zA-Z]/.test(url) && !url.startsWith('http'))
    return isPathnameOnly ? url : '/'
  }
}

export const omitQueryParamsFromPathname = (pathname: string) => {
  let queryParamsStartIndex = pathname.indexOf('?')

  if (queryParamsStartIndex !== -1) {
    pathname = pathname.slice(0, queryParamsStartIndex)
  }
  return pathname
}
