import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { merge, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { AccountStateEnum } from 'src/app/enums/account.enums'
import { BillingFrequencyEnum } from 'src/app/enums/billing.enums'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { LoginEnum } from 'src/app/enums/login.enum'
import { ApiPlanLevelEnum, ApiPlanLevelEnumLabel } from 'src/app/enums/service-plan.enums'
import { IApiStore } from 'src/app/merchant-store/models/iapi-store'
import { IPaymentMethod } from 'src/app/payment-method/models/ipayment-method.model'
import * as authSelectors from 'src/app/user/selectors/user.selectors'
import { State as AuthState } from 'src/app/user/user.state'

import { AccountDatabase } from '../db/account.db'
import { IAccount, IApiGoLiveStatus, IConversionStatus } from '../models/iaccount'
import { IApiAccount } from '../models/iapi-account'
import { IIndustryClassification } from '../models/iindustry-classification'
import { IProfile } from '../models/iprofile.model'
import { AccountAdapter } from '../utilities/account-adapter.utilities'
import { LocalStorageNameSpaceEnum } from './../../enums/local-storage.enums'
import { State } from './../account.state'
import * as fromSelectors from './../selectors/account.selectors'
import { ICancellationQuestionnaire } from 'src/app/manage-account/models/icancellationQuestionnaire'
import * as billingSelector from 'src/app/manage-account/selectors/billing.selector'
import { State as BillingState } from 'src/app/manage-account/manage-account.state'
import { IApiMerchant } from '../models/iapi-merchant'

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private db = new AccountDatabase()
  private classificationCache = new Map<string, IIndustryClassification>()
  private cachedUrls: IApiStore[]
  private adapter = new AccountAdapter(this.db)

  constructor(
    private accountStore: Store<State>,
    private authStore: Store<AuthState>,
    private appStatus: Store<AppStatusState>,
    private http: HttpClient,
    private billingStore: Store<BillingState>
  ) {}

  public getAccount(): Observable<IAccount> {
    return merge(this.getAccountDataFromLocal(), this.getAccountFromApiWhenOnline()).pipe(
      map(([account, source]) => account)
    )
  }

  public refreshAccount(): Observable<IAccount> {
    return this.refreshAccountData().pipe(map(([account, source]) => account))
  }

  public getAccountState(): AccountStateEnum {
    return this.db.getAccountState()
  }

  public saveAccountToLocal(account: IAccount): Observable<IAccount> {
    this.db.saveAccount(account)
    return of(this.db.getAccount())
  }

  public getLocalSavedAccount(): Observable<IAccount | null> {
    return of(this.db.getAccount())
  }
  public getLocalConversionStatus(): IConversionStatus | null {
    const acct = this.db.getAccount()
    if (!acct) {
      return
    }
    return acct.conversionStatus ? acct.conversionStatus : null
  }

  public updateLocalConversionStatus(update: IConversionStatus): void {
    const accountData = localStorage.getItem(LocalStorageNameSpaceEnum.Account)
    if (!accountData) {
      return
    }

    const parsed = JSON.parse(accountData) as IAccount
    parsed.conversionStatus = { ...parsed.conversionStatus, ...update }
    localStorage.setItem(LocalStorageNameSpaceEnum.Account, JSON.stringify(parsed))
  }

  public clearLocalSavedAccountData(): Observable<void> {
    this.db.clearAccount()
    return of(null)
  }

  public getGoLiveStatus(): Observable<IConversionStatus> {
    return this.accountStore.pipe(
      select(fromSelectors.selectMerchantId),
      switchMap((merchantId) => this.http.post(`api/go-live-status/${merchantId}`, {})),
      map((result: IApiGoLiveStatus) => this.adapter.mapGoLiveApiToConversionStatus(result)),
      tap((conversion) => this.updateLocalConversionStatus(conversion))
    )
  }

  public updateAccountProfile(profile: IProfile): Observable<IApiMerchant> {
    return this.http
      .post<IApiMerchant>(`api/profile/update`, this.adapter.mapProfileToApiProfile(profile))
      .pipe(
        tap((reply) => {
          this.db.updateAccount(reply)
        })
      )
  }

  public turnOnAutomatedCompliance(billingSelected: BillingFrequencyEnum): Observable<IAccount> {
    return this.http.post(`api/account/toggle-ac`, { serviceplan: '1' }).pipe(
      map((result: IApiAccount) => {
        const { merchant, urls, locations } = result
        this.cachedUrls = urls
        return this.adapter.mapApiAccountResultToAccount(merchant, urls, [], locations)
      })
    )
  }

  public turnOnSelectiveCompliance(cancelsst: boolean): Observable<IAccount> {
    return this.http.post(`api/account/toggle-ac`, { serviceplan: '2', cancelsst }).pipe(
      map((result: IApiAccount) => {
        const { merchant, urls, locations } = result
        this.cachedUrls = urls
        return this.adapter.mapApiAccountResultToAccount(merchant, urls, [], locations)
      })
    )
  }

  public turnOnGrandfatheredSelectedCompliance(
    billingSelected: BillingFrequencyEnum,
    servicePlan: ApiPlanLevelEnum
  ): Observable<IAccount> {
    return this.http
      .post(`api/account/toggle-ac`, {
        pricingPlan: ApiPlanLevelEnumLabel.get(servicePlan),
        billingSelected,
      })
      .pipe(
        map((result: IApiAccount) => {
          const { merchant, urls, locations } = result
          this.cachedUrls = urls

          return this.adapter.mapApiAccountResultToAccount(merchant, urls, [], locations)
        })
      )
  }

  public addToPrepayServiceAccount(amount: number): Observable<any> {
    return this.http.post(`api/account/prepayapiusage`, { prepayamount: amount }).pipe(
      map((result: IApiAccount) => {
        const { merchant, urls } = result
        this.cachedUrls = urls
        return this.adapter.mapApiAccountResultToAccount(merchant, urls, [])
      })
    )
  }

  public extendConfigurationWindow(paymentMethod: IPaymentMethod): Observable<any> {
    return this.http
      .post(`api/account/extend-configuration-window`, {
        selectedPaymentID: paymentMethod.paymentProfileId,
      })
      .pipe(
        map((result: any) => {
          if (result && result.result) {
            if (result.result === 1) {
              throw new Error(result.message)
            }
          }
          return result
        })
      )
  }

  public searchIndustryClassification(searchTerm: string): Observable<IIndustryClassification[]> {
    return this.http
      .post(`api/profile/naics`, { searchterm: searchTerm })
      .pipe(
        tap((items: IIndustryClassification[]) =>
          items.forEach((item) => this.classificationCache.set(item.code.toString(), item))
        )
      )
  }

  public getCurrentIndustryClassification(): Observable<IIndustryClassification> {
    return this.http
      .get(`api/profile/my-naics`)
      .pipe(map((items: IIndustryClassification[]) => items[0]))
  }

  public updateIndustryClassification(industryCode: number): Observable<IApiMerchant> {
    return this.http.post(`api/profile/save-naics`, { naics: industryCode }).pipe(
      map((merchant: IApiMerchant) => {
        return merchant
      })
    )
  }

  public cancelAccount(id: number, cancelsst: boolean, questionnaire: ICancellationQuestionnaire) {
    return this.http.post(`api/account/close-request/${id}`, { cancelsst }).pipe(
      switchMap((result: IApiAccount) => {
        const { merchant, urls, locations } = result
        this.cachedUrls = urls
        const acctResult = this.adapter.mapApiAccountResultToAccount(merchant, urls, [], locations)
        return this.http
          .post(
            'api/CancellationQuestionnaire',
            this.adapter.mapQuestionnaireToApiQuestionnaire(questionnaire)
          )
          .pipe(
            map(() => {
              return {
                ...acctResult,
              }
            })
          )
      })
    )
  }

  public reActivateAccount(id: number) {
    return this.http.post(`api/account/reverse-close-request/${id}`, {}).pipe(
      map((result: IApiAccount) => {
        const { merchant, urls, locations } = result
        this.cachedUrls = urls
        return this.adapter.mapApiAccountResultToAccount(merchant, urls, [], locations)
      })
    )
  }

  private getAccountFromApiWhenOnline(): Observable<[IAccount, DataSourceEnum]> {
    return this.appStatus.select(fromAppStatusSelectors.selectOnline).pipe(
      withLatestFrom(this.authStore.select(authSelectors.selectLoginStatus)),
      filter(([online, status]) => online && status === LoginEnum.LoggedIn),
      switchMap(() => this.refreshAccountData())
    )
  }

  private refreshAccountData(): Observable<[IAccount, DataSourceEnum]> {
    return this.http.post('api/account/refresh', {}).pipe(
      map((result: IApiAccount) => {
        const { merchant, urls, appIDs, locations } = result
        this.cachedUrls = urls
        return this.adapter.mapApiAccountResultToAccount(merchant, urls, appIDs, locations)
      }),
      tap((account) => this.db.saveAccount(account)),
      map((account) => [account, DataSourceEnum.API])
    )
  }

  private getAccountDataFromLocal(): Observable<[IAccount, DataSourceEnum]> {
    return of(this.db.getAccount()).pipe(
      withLatestFrom(this.accountStore.select(accountSelector.selectDataSource)),
      filter(([locations, source]) => {
        if (source === DataSourceEnum.API) {
          return false
        }
      }),
      map(([locations, _]) => [locations, DataSourceEnum.Local])
    )
    // return of([this.db.getAccount(), DataSourceEnum.Local])
  }

  public saveAccountState(accountState: AccountStateEnum) {
    const update = this.db.updateAccount({ accountState })
    return of(update)
  }

  public checkEin(ein: string): Observable<boolean> {
    return this.http.get(`api/checkein?ein=${ein}`).pipe(map((isFound: boolean) => isFound))
  }

  public associatestoreinfo(storeInfoGuid): Observable<boolean> {
    return this.http.get(`api/account/associatestoreinfo?storeInfoguid=${storeInfoGuid}`).pipe(
      map((success: boolean) => {
        return success
      }),
      catchError(() => {
        return of(false)
      })
    )
  }
}
