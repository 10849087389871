import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'

import { BehaviorSubject, Observable } from 'rxjs'
import { NotificationService } from 'src/app/notification/services/notification.service'

@Component({
  selector: 'tc-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent implements OnInit {
  private justCopiedSubject = new BehaviorSubject<boolean>(false)
  justCopied$: Observable<boolean> = this.justCopiedSubject.asObservable()

  @Input() valueToCopy: string
  @Input() description?: string
  @Input() defaultCopyIcon?: string = 'pi-clone'

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {}

  copyMessage() {
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = this.valueToCopy
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)

    this.justCopiedSubject.next(true)
    setTimeout(() => {
      this.justCopiedSubject.next(false)
    }, 2000)

    this.notificationService.showSuccess({
      title: 'Copied!',
      description: this.description ? `${this.description} copied` : 'Content copied',
    })
  }

  getReadyToCopyClass(): any {
    return `ml-3 cursor-pointer pi ${this.defaultCopyIcon}`
  }
}
