import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, Observable } from 'rxjs'
import { delay, filter, first, map, pluck, shareReplay, skip, tap } from 'rxjs/operators'
import * as appStatusActions from 'src/app/app-status/actions/app-status.actions'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import * as appStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { BlogService } from 'src/app/blog/services/blog.service'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'
import { LoginEnum } from 'src/app/enums/login.enum'
import { SupportCategoryEnumLabel } from 'src/app/enums/support.enums'
import { INotification } from 'src/app/notification/models/inotification.model'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import { FeatureFlagService } from 'src/app/root/services/feature-flag.service'
import { USStateService } from 'src/app/us-states/services/us-state.service'
import * as userActions from 'src/app/user/actions/login.actions'
import * as userSelector from 'src/app/user/selectors/user.selectors'
import { State as UserState } from 'src/app/user/user.state'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'
import { environment } from 'src/environments/environment'
import { SubSink } from 'subsink'
import { State as SetupState } from 'src/app/setup/setup.state'
import * as fromSetupActions from 'src/app/setup/actions/setup.actions'

interface ILayout {
  mode: string
  disableClose: boolean
  opened: boolean
}

@Component({
  templateUrl: './shell-public.component.html',
  styleUrls: ['./shell-public.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellPublicComponent implements OnInit, OnDestroy {
  storeInfoGuid$ = new BehaviorSubject<string>('')
  public publicSiteUrl = environment.publicSiteUrl

  layout$: BehaviorSubject<ILayout> = new BehaviorSubject({
    mode: 'side',
    disableClose: false,
    opened: false,
  })

  options: IOption[] = getOptionsFromLabel(SupportCategoryEnumLabel)
  host$ = new BehaviorSubject<string>(null)
  subs = new SubSink()

  public screenWidth: number

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userStore: Store<UserState>,
    private breakpointObserver: BreakpointObserver,
    private usStateService: USStateService,
    private route: Store<fromRouter.RouterReducerState<any>>,
    private appStatusStore: Store<AppStatusState>,
    private featureFlagService: FeatureFlagService,
    private metaTagService: Meta,
    private titleServce: Title,
    private blogService: BlogService,
    private setupStore: Store<SetupState>
  ) {
    this.usStateService.getUsStatesFromPublic().pipe(first()).subscribe()
  }

  public isSmallPage$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(pluck('matches'))

  public isSignedIn$: Observable<boolean> = this.userStore
    .select(userSelector.selectLoginStatus)
    .pipe(map((status) => status !== LoginEnum.LoggedOut))

  public isInstallable$: Observable<boolean> = this.appStatusStore.select(
    appStatusSelectors.selectIsInstallable
  )

  public blogNotifications$: Observable<INotification[]> = this.blogService
    .getBlogAlerts()
    .pipe(shareReplay(1))

  public blogAlert$: Observable<boolean> = this.blogNotifications$.pipe(
    map((msgs) => !!msgs.find((msg) => msg.type === AlertTypeEnum.BlogAlert))
  )
  public storeGuid$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'storeinfoguid'),
    filter((storeGuid: string) => !!storeGuid)
  )

  ngOnInit(): void {
    this.screenWidth = window.innerWidth

    // this.dynamicallyLoadScript();
    this.subs.add(
      this.route
        .pipe(
          skip(1),
          tap(() => (document.querySelector('mat-sidenav-content').scrollTop = 0))
        )
        .subscribe()
    )

    this.subs.add(
      this.route
        .select(fromRouterSelectors.selectHost)
        .pipe(tap((host) => this.host$.next(host)))
        .subscribe()
    )

    this.subs.add(
      this.route
        .select(fromRouterSelectors.selectDescription)
        .pipe(
          tap((description) => {
            this.metaTagService.updateTag({ name: 'description', content: description })
          })
        )
        .subscribe()
    )

    this.subs.add(
      this.route
        .select(fromRouterSelectors.selectTitle)
        .pipe(
          delay(100), // not sure why it needs this
          tap((title) => this.titleServce.setTitle(title))
        )
        .subscribe()
    )

    this.subs.add(
      this.storeGuid$
        .pipe(
          first(),
          delay(100),
          tap((storeGuid) => {
            this.storeInfoGuid$.next(storeGuid)
          })
        )
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  public toggle(): void {
    const current: ILayout = this.layout$.value
    if (current.disableClose) {
      this.layout$.next({ ...current, opened: true })
    } else {
      this.layout$.next({ ...current, opened: !current.opened })
    }
  }

  public signIn(): void {
    if (this.storeInfoGuid$.value) {
      this.router.navigate(['/sign-in'], {
        queryParams: { storeinfoguid: this.storeInfoGuid$.value },
      })
    } else {
      this.router.navigate(['/sign-in'])
    }
  }

  // TODO remove
  private dynamicallyLoadScript(): void {
    if (!(window as any).Trustpilot) {
      const trustPilot = document.createElement('script')
      trustPilot.setAttribute(
        'src',
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      )
      document.head.appendChild(trustPilot)
    }
  }

  public lock(): void {
    this.userStore.dispatch(new userActions.LockApplication())
  }

  public signOut(): void {
    this.userStore.dispatch(new userActions.Logout())
  }

  public installApp(): void {
    this.appStatusStore.dispatch(new appStatusActions.InstallApp())
  }

  public findRate(): void {
    this.router.navigate(['find-a-rate'])
  }

  public goHome(): void {
    this.router.navigate(['/home'])
  }

  public goToPricing(): void {
    const url = new URL(`${this.publicSiteUrl}/pricing`)
    window.open(url.toString(), '_self')
  }

  public goToStateGuides(): void {
    this.router.navigate(['/state-guides'])
  }

  public goToEnhancedServices(): void {
    this.router.navigate(['/pricing/enhanced-services'])
  }

  public goToCoreServices(): void {
    this.router.navigate(['/pricing/core-services'])
  }

  public goToSigninHome(): void {
    this.router.navigate(['/go'])
  }

  public goToRegister(): void {
    this.router.navigate(['/register'])
  }

  public goToSupport(): void {
    const url = new URL(`${this.publicSiteUrl}/support`)
    window.open(url.toString(), '_self')
  }

  public goToBlogs(): void {
    this.router.navigate(['blog'])
  }

  getLogo(): string {
    if (this.screenWidth < 767) {
      return 'assets/icons/txc-icon.png'
    } else {
      return 'assets/icons/taxcloud-logo-text.svg'
    }
  }
}
