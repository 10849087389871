import { Component } from '@angular/core'
import * as fromRouter from '@ngrx/router-store'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import { IBreadCrumb } from '../../models/ibreadcrumb.models'
import { Router } from '@angular/router'

@Component({
  selector: 'tc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass'],
})
export class BreadcrumbComponent {
  constructor(
    private route: Store<fromRouter.RouterReducerState<any>>,
    private router: Router
  ) {
    this.route
      .select(fromRouterSelectors.selectBreadcrumbs)
      .pipe(
        tap((breadcrumbs) => {
          document.title = `TaxCloud ${breadcrumbs.map((crumb) => crumb.label).join(' ')}`
        })
      )
      .subscribe()
  }

  getRouterLink(breadcrumb: IBreadCrumb): string {
    const breadcrumbPath = breadcrumb.path
    const pathHasDynamicPart = breadcrumbPath.includes(':')

    if (!pathHasDynamicPart) {
      return breadcrumbPath
    }

    const currentUrl = this.router.url
    const currentBreadcrumbLength = breadcrumbPath.split('/').length
    const currentUrlParts = currentUrl.split('/')
    const dynamicReturnUrl = currentUrlParts.slice(0, currentBreadcrumbLength).join('/')

    return dynamicReturnUrl
  }

  breadcrumbs$: Observable<IBreadCrumb[]> = this.route.select(fromRouterSelectors.selectBreadcrumbs)
}
